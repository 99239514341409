'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:EasplashCtrl
 * @description
 * # EasplashCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaSplashCtrl', function ($scope, $state, $stateParams, $eaBackendDataModeSelector, $location, $eaConfig) {

  // resolve the csp entry point
  if (($location.host().indexOf($eaConfig.feHost) > 0 && $location.host().split('.')[0] === 'csp')) {
    $state.go('authorized.cspinit');
  } else {
    // go directly to the signin state
    $state.go('public.signin');
  }

});
