'use strict';

angular.module('azureCostsFeApp').controller('EaDebuggerCspCtrl', function ($scope, $state, $eaBackendAzure) {

  $scope.PartnerSdkConnection = {
    ClientId: "",
    ClientSecret: "",
    Domain: "",
    Username: "",
    Password: ""
  };

  $scope.PartnerSdkOperations = [];
  $scope.date = new Date();

  $scope.HideForm = false;

  $scope.performConnectionTest = function() {
    $scope.HideForm = true;
    $scope.date = new Date();

    var optionalUserCredentials = undefined;

    if ($scope.PartnerSdkConnection.Username && $scope.PartnerSdkConnection.Username.length > 0) {
      optionalUserCredentials = {
        Username: $scope.PartnerSdkConnection.Username,
        Password: $scope.PartnerSdkConnection.Password
      }
    }

    $eaBackendAzure.dataMonDebugCsp($scope.PartnerSdkConnection.ClientId, $scope.PartnerSdkConnection.ClientSecret, $scope.PartnerSdkConnection.Domain, optionalUserCredentials).then(function(result) {
      result.forEach(function(r) {
        $scope.PartnerSdkOperations.push(r);
      });
    })
  }

});
