'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddStep02CostMgntCtrl', function ($scope, $eaBackend, $busy, jwtHelper, $state, $eaCurrentSession) {

  $scope.formAddReport = {
    team: $state.params.team ? $state.params.team : 'personal',
    storageAccount: null,
    storageKey: null,
    reportContainer: null,
    reportFolder: null
  };

  $scope.next = function() {    
    $busy.during($eaBackend.dataCostMgntRegisterContract($scope.formAddReport.team, $eaCurrentSession.DataToken).then(function(contractId) {      
      return $busy.during($eaBackend.dataCostMgntAddContainerToContract($scope.formAddReport.team, contractId, $scope.formAddReport.storageAccount,  $scope.formAddReport.storageKey, $scope.formAddReport.reportContainer, $scope.formAddReport.reportFolder, $eaCurrentSession.DataToken).then(function() {
        return $busy.during($eaBackend.dataAllSyncReports($scope.formAddReport.team, contractId, $eaCurrentSession.DataToken).then(function() {
          return $state.go('authorized.reportAdd.step03', { team: $scope.formAddReport.team, enrollment: contractId});
        }))
      }))
    }).catch(function (error) {

      if (error.status === 409 && error.data && error.data.Message) {
        $scope.registerError = error.data.Message;
      } else {
        $scope.registerError = error.data + ' (' + error.statusText + ')';
      }
    }));
  };
});
