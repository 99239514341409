'use strict';

angular.module('azureCostsFeApp').controller('JwtTokenHelperCtrlMixin', [ '$scope', 'jwtHelper', function($scope, jwtHelper) {

  $scope.verifyJwtToken = function(tokenValue) {

    try {
      // read the token from the input field
      var decodedToken = jwtHelper.decodeToken(tokenValue);

      // check if we have an enrollment number, if so add
      if (decodedToken.EnrollmentNumber !== undefined) {
        return decodedToken.EnrollmentNumber;
      } else {
        return null;
      }

    } catch(e) {
        return null;
    }
  }
}]);
