'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddCtrl', function ($scope, $state, $rootScope, $acWizardHelpers) {

  // define the current state
  $scope.currentStep = $acWizardHelpers.getCurrentStep($state.current);
  $scope.step01Title = 'Select your contract type';

  // the error container
  $scope.registerError = null;

  // handle state changes to adapt the control
  $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
    $scope.currentStep = $acWizardHelpers.getCurrentStep(toState);
  });

  // just redirect to the step01 as default
  if ($state.current.name === 'authorized.reportAdd') { return $state.go('authorized.reportAdd.step01'); }
});
