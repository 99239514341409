'use strict';

/**
 * @ngdoc overview
 * @name azureCostsFeApp
 * @description
 * # azureCostsFeApp
 *
 * Main module of the application.
 */
angular
  .module('azureCostsFeApp', [
    'app.env',
    'ngHelperAirbrake',        
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'ngHelperBusy',
    'ngHelperCropResize',
    'angularMoment',
    'ngHelperUserVoice',
    'ngHelperDynamicTemplateLoader',
    'ng.deviceDetector',
    'ui.bootstrap',
    'uiSwitch',
    'angular-jwt',
    'ngHelperToolbar',
    'ui.gravatar',
    'xeditable',
    'minicolors',
    'ui.ace',
    'ui.router',
    'gridster',
    'uuid',
    'base64',
    'ngToast',
    'timer'
  ])
.config(function ($stateProvider, $urlRouterProvider, $locationProvider, $uservoiceProvider, $dynamicTemplateLoaderProvider, $airbrakeProvider, ngToastProvider, $sceDelegateProvider, envDeployment) {

  console.log("Application is loading");

  // define our trusted resources
  $sceDelegateProvider.resourceUrlWhitelist([
    // Allow same origin resource loads.
    'self',

    // Allow loading from our assets domain.
    'https://' + envDeployment.cdnHostDev + '/**',
    'https://' + envDeployment.cdnHostProd + '/**'
  ]);

  // configure airbrake
  if (location.host.indexOf('costs-test.azurewebsites.net') !== -1) {
    $airbrakeProvider.setProject('316459', 'd61ff188652159fa8077e92ec72b0884', 'test');
  } else if (location.host.indexOf('azure-costs.com') !== -1 || location.host.indexOf('azure-costs.de') !== -1 || location.host.indexOf('cloud-costs.com') !== -1) {
    // set the airbrake project id
    $airbrakeProvider.setProject('316459', 'd61ff188652159fa8077e92ec72b0884', 'production');
  }

  // set html5 mode
  $locationProvider.html5Mode(true);

  // Register the HTTP interceptors
  $dynamicTemplateLoaderProvider.registerInterceptors();

  // Configure the UserVoice provider
  $uservoiceProvider.setSubDomain('azurecostmonitor');
  $uservoiceProvider.setApiKey('g0PclZtSbWOApdniE89ZLw');

  // configure the toast
  ngToastProvider.configure({
    animation: 'fade',
    verticalPosition: 'bottom',
    horizontalPosition: 'left',
    dismissButton: true
  });

  // default route
  $urlRouterProvider.otherwise('/');

  // routing states
  $stateProvider

    // helper state for unauthorized
    .state('public', {
      abstract: true,
      templateUrl: 'views/ea-public-area.html',
      controller: 'EaPublicAreaCtrl'
    })

    // Public Pages
    .state('public.landing', {
      url: '/?offline',
      template: '<div></div>',
      controller: 'EaSplashCtrl'
    }).state('public.signin', {
      url: '/signin?team&enrollment&initial&idp&csp&demo',
      templateUrl: 'views/ea-signin.html',
      controller: 'EaSignInCtrl'
    }).state('public.debuggerCsp', {
      url: '/debugger/csp',
      templateUrl: 'views/debugger/csp.html',
      controller: 'EaDebuggerCspCtrl'
    })

    // Used SSO
    .state('ssoCallback', {
      url: '/sso/callback?state&access_token&server_error',
      templateUrl: 'views/sso/ea-callback.html',
      controller: 'EaSSOCallbackCtrl'
    })
      // SSO
    .state('ssoFinish', {
      url: '/sso/finish?redirect&access_token',
      templateUrl: 'views/sso/ea-idp.html',
      controller: 'EaSSOFinishCtrl'
    }).state('ssoAccessDenied', {
      url: '/sso/access_denied',
      templateUrl: 'views/sso/ea-sso-access-denied.html',
      controller: 'EaSSOAccessDeniedCtrl'
    })

    // helper state for authorizing
    .state('authorized', {
      abstract: true,
      templateUrl: 'views/ea-secured-area.html',
      controller: 'EaSecuredAreaCtrl',
      resolve: {
        '$eaCurrentSession': ['$eaSession2', function($eaSession2) {
          return $eaSession2.lazy();
        }]
      }
    })

    // Global Add Report
    .state('authorized.reportAdd', {
      url: '/report/add?teamid',
      templateUrl: 'views/add/ea-add.html',
      controller: 'EaAddCtrl'
    })
    .state('authorized.reportAdd.step01', {
      url: '/step01',
      templateUrl: 'views/add/ea-add-step01.html',
      controller: 'EaAddStep01Ctrl'
    })
    .state('authorized.reportAdd.step02-ea', {
      url: '/step02/ea?team',
      templateUrl: 'views/add/ea-add-step02-ea.html',
      controller: 'EaAddStep02EaCtrl'
    })
    .state('authorized.reportAdd.step02-rate', {
      url: '/step02/rate?team&enrollment&csp&currency',
      templateUrl: 'views/add/ea-add-step02-rate.html',
      controller: 'EaAddStep02RateCtrl'
    })
    .state('authorized.reportAdd.step02-rate-connect', {
      url: '/step02/rate-connect?team&enrollment&csp',
      templateUrl: 'views/add/ea-add-step02-rate-connect.html',
      controller: 'EaAddStep02RateConnectCtrl'
    })
    .state('authorized.reportAdd.step02-costmgnt', {
      url: '/step02/costmgnt?team',
      templateUrl: 'views/add/ea-add-step02-costmgnt.html',
      controller: 'EaAddStep02CostMgntCtrl'
    })
    .state('authorized.reportAdd.step03', {
      url: '/step03?team&enrollment&csp',
      templateUrl: 'views/add/ea-add-step03.html',
      controller: 'EaAddStep03Ctrl'
    })

    // Profile related things
    .state('authorized.profileBilling', {
      url: '/billing',
      templateUrl: 'views/billing/ea-billing.html',
      controller: 'EaBillingCtrl'
    }).state('authorized.profileBillingStartTrial', {
      url: '/billing/starttrial',
      templateUrl: 'views/billing/ea-start-trial.html',
      controller: 'EaBillingStartTrialCtrl'
    }).state('authorized.profileBillingUpgradeEdition', {
      url: '/billing/upgradeedition',
      templateUrl: 'views/billing/ea-upgrade-edition.html',
      controller: 'EaBillingUpgradeEditionCtrl'
    }).state('authorized.profileBillingServiceProvider', {
      url: '/billing/serviceprovider',
      templateUrl: 'views/billing/ea-service-provider.html',
      controller: 'EaBillingServiceProviderCtrl'
    })

    // Teams related things
    .state('authorized.teamsMigrate', {
      url: '/teams/migrate',
      templateUrl: 'views/teams/ea-team-migrate.html',
      controller: 'EaTeamMigrateCtrl'
    }).state('authorized.teamsInvitationAccept', {
      url: '/teams/invitation/accept',
      templateUrl: 'views/teams/ea-team-invite-accept.html',
      controller: 'EaTeamInviteAcceptCtrl'
    }).state('authorized.teamsManage', {
      url: '/teams/:teamid/manage',
      templateUrl: 'views/teams/manage/ea-team-manage.html',
      controller: 'EaTeamManageCtrl'
    }).state('authorized.teamsManageAADLink', {
      url: '/teams/:teamid/manage/aad/link?tid&scope',
      templateUrl: 'views/teams/manage/aad/ea-team-link-aad.html',
      controller: 'EaTeamLinkAADCtrl'
    }).state('authorized.teamsManageAADEdit', {
      url: '/teams/:teamid/manage/aad/edit',
      templateUrl: 'views/teams/manage/aad/ea-team-edit-aad.html',
      controller: 'EaTeamEditAADCtrl'
    }).state('authorized.teamsManageBuckets', {
      url: '/teams/:teamid/manage/buckets',
      templateUrl: 'views/teams/manage/buckets/ea-team-buckets.html',
      controller: 'EaTeamBucketsCtrl'
    }).state('authorized.teamsManageSettings', {
      url: '/teams/:teamid/manage/settings',
      templateUrl: 'views/teams/manage/settings/ea-team-settings.html',
      controller: 'EaTeamSettingsCtrl'
    })

    // Teams related things (v2)
    .state('authorized.teams', {
      abstract: true,
      url: '/teams/:teamid',
      template: '<div ui-view></div>'
    }).state('authorized.teams.dashboards', {
      url: '/dashboards?initial&active&cycle&day&nomargin&version',
      templateUrl: 'views/teams/dashboards/ea-dashboards.html',
      controller: 'EaDashboardsCtrl'
    }).state('authorized.teams.contracts', {
      abstract: true,
      url: '/contracts',
      template: '<div ui-view></div>'
    }).state('authorized.teams.contracts.add', {
      url: '/add',
      templateUrl: 'views/teams/contracts/cc-add-contract.html',
      controller: 'CcAddContractCtrl'
    }).state('authorized.teams.contracts.add-aws', {
      url: '/aws',
      templateUrl: 'views/teams/contracts/aws/cc-aws.html',
      controller: 'CcAddAwsContractCtrl'
    }).state('authorized.teams.contracts.add-aws.prepare', {
      url: '/prepare',
      templateUrl: 'views/teams/contracts/aws/cc-aws-prepare.html',
      controller: 'CcAddAwsContractPrepareCtrl'
    }).state('authorized.teams.contracts.add-aws.connect', {
      url: '/connect',
      templateUrl: 'views/teams/contracts/aws/cc-aws-connect.html',
      controller: 'CcAddAwsContractConnectCtrl'
    }).state('authorized.teams.contracts.add-aws.finish', {
      url: '/finish?cid',
      templateUrl: 'views/teams/contracts/aws/cc-aws-dashboard.html',
      controller: 'CcAddAwsContractDashboardCtrl'
    }).state('authorized.teams.contracts.add-gcp', {
      url: '/gcp',
      templateUrl: 'views/teams/contracts/gcp/cc-gcp.html',
      controller: 'CcAddGcpContractCtrl'
    }).state('authorized.teams.contracts.add-gcp.prepare', {
      url: '/prepare',
      templateUrl: 'views/teams/contracts/gcp/cc-gcp-prepare.html',
      controller: 'CcAddGcpContractPrepareCtrl'
    }).state('authorized.teams.contracts.add-gcp.connect', {
      url: '/connect',
      templateUrl: 'views/teams/contracts/gcp/cc-gcp-connect.html',
      controller: 'CcAddGcpContractConnectCtrl'
    }).state('authorized.teams.contracts.add-gcp.finish', {
      url: '/finish?cid',
      templateUrl: 'views/teams/contracts/gcp/cc-gcp-dashboard.html',
      controller: 'CcAddGcpContractDashboardCtrl'
    }).state('authorized.teams.rimanagement', {
      url: '/rimanagement?contract&service',
      templateUrl: 'views/teams/rimanagement/ea-rimanagement.html',
      controller: 'EaRiManagementCtrl'
    })

    // CSP Init
    .state('authorized.cspinit', {
      url: '/csp',
      templateUrl: 'views/csp/ac-csp-init.html',
      controller: 'AcCspInitCtrl',
      resolve: {
        '$cspFeatureEnabled': ['$eaConfig', '$q', function ($eaConfig, $q) {
          return $eaConfig.verifyFeature('csp');
        }]
      }
    })
    // CSP
    .state('authorized.csp', {
      url: '/csp/:resellerId',
      template: '<div><div ng-if="Profile" ui-view></div></div>',
      controller: 'AcCspCtrl',
      abstract: true,
      resolve: {
        '$cspFeatureEnabled': ['$eaConfig', '$q', function ($eaConfig, $q) {
          return $eaConfig.verifyFeature('csp');
        }]
      }
    }).state('authorized.csp.accounts', {
      url: '/accounts?version&active',
      templateUrl: 'views/csp/ac-csp-accounts.html',
      controller: 'AcCspAccountsCtrl'
    }).state('authorized.csp.gsi', {
      url: '/integrators',
      templateUrl: 'views/csp/ac-csp-gsi.html',
      controller: 'AcCspGsiCtrl'
    }).state('authorized.csp.wadd', {
      url: '/accounts/add',
      templateUrl: 'views/csp/add/ac-csp-add.html',
      controller: 'AcCspAddCtrl'
    }).state('authorized.csp.wadd.step01', {
      url: '/step01',
      templateUrl: 'views/csp/add/ac-csp-add-step01.html',
      controller: 'AcCspAddStep01Ctrl'
    }).state('authorized.csp.wadd.step02-t1', {
      url: '/step02/t1',
      templateUrl: 'views/csp/add/ac-csp-add-step02-t1.html',
      controller: 'AcCspAddStep02T1Ctrl'
    }).state('authorized.csp.wadd.step02-t2', {
      url: '/step02/t2',
      templateUrl: 'views/csp/add/ac-csp-add-step02-t2.html',
      controller: 'AcCspAddStep02T2Ctrl'
    }).state('authorized.csp.wadd.step02-ea', {
      url: '/step02/ea',
      templateUrl: 'views/csp/add/ac-csp-add-step02-ea.html',
      controller: 'AcCspAddStep02EACtrl'
    }).state('authorized.csp.wadd.step02-aws', {
      url: '/step02/aws',
      templateUrl: 'views/csp/add/ac-csp-add-step02-aws.html',
      controller: 'AcCspAddStep02AWSCtrl'
    }).state('authorized.csp.wadd.step02-v1', {
      url: '/step02/v1',
      templateUrl: 'views/csp/add/ac-csp-add-step02-v1.html',
      controller: 'AcCspAddStep02V1Ctrl'
    }).state('authorized.csp.wadd.step02-demo', {
      url: '/step02/demo',
      templateUrl: 'views/csp/add/ac-csp-add-step02-demo.html',
      controller: 'AcCspAddStep02DemoCtrl'
    }).state('authorized.csp.wadd.step03', {
      url: '/step03',
      templateUrl: 'views/csp/add/ac-csp-add-step03.html',
      controller: 'AcCspAddStep03Ctrl'
    }).state('authorized.csp.resellermanagement', {
      url: '/resellers',
      templateUrl: 'views/csp/resellers/ac-csp-reseller-management.html',
      controller: 'AcCspResellerManagementCtrl'
    })

    // Admin things
    .state('authorized.admin', {
      abstract: true,
      url: '/admin',
      templateUrl: 'views/admin/ea-admin.html',
      controller: 'EaAdminCtrl'
    }).state('authorized.admin.contracts', {
      url: '/contracts',
      templateUrl: 'views/admin/ea-admin-contracts.html',
      controller: 'EaAdminContractsCtrl'
    }).state('authorized.admin.users', {
      url: '/users',
      templateUrl: 'views/admin/ea-admin-users.html',
      controller: 'EaAdminUsersCtrl'
    }).state('authorized.admin.jobs', {
      url: '/jobs',
      templateUrl: 'views/admin/ea-admin-jobs.html',
      controller: 'EaAdminJobsCtrl'
    }).state('authorized.admin.jobs-details', {
      url: '/jobs/byhash/:jobpartition/details',
      templateUrl: 'views/admin/ea-admin-jobs-details.html',
      controller: 'EaAdminJobsDetailsCtrl'
    }).state('authorized.admin.jobs-details-advanced', {
      url: '/jobs/bymonth/:jobmonth/partition/:jobpartition/details',
      templateUrl: 'views/admin/ea-admin-jobs-details.html',
      controller: 'EaAdminJobsDetailsCtrl'
    }).state('authorized.admin.jobs-details-old', {
      url: '/jobs/:jobpartition/details',
      templateUrl: 'views/admin/ea-admin-jobs-details.html',
      controller: 'EaAdminJobsDetailsCtrl'
    });
  })
  .run(function($airbrake, $http, $dynamicTemplateLoader, $eaTemplateCachingStrategy, $location, $window, editableOptions, $eaSession2, $rootScope, $eaBranding, $eaConfig) {

      // should we every use the 127.0.0.1 redirect us to localhost
      if ($location.$$host === '127.0.0.1') {
        $window.location = $location.absUrl().replace('http://127.0.0.1', 'http://localhost');
        return;
      }
      
      // configure xeditable
      editableOptions.theme = 'bs3';

      // configure the template loader
      $dynamicTemplateLoader.setCachingStrategyService($eaTemplateCachingStrategy);

      // register the session plugins
      $eaSession2.onUpdate(function(session) {
        // set the airbrake context
        $airbrake.setCustomContext({userId: session.UserId, contact: session.Contact });
      });

      $eaSession2.onRegister(function(session) {
        // set the airbrake context
        $airbrake.setCustomContext({userId: session.UserId, contact: session.Contact });
      });

      $eaSession2.onDestroying(function(/* session */) {
        // reset the airbrake context
        $airbrake.setCustomContext({});
      });

      // Load the branding during every page reload
      $eaBranding.loadBrandingFromHostSegment();
  });
