'use strict';

angular.module('azureCostsFeApp').controller('SessionHelperCtrlMixin', function($scope, $eaBackendDataModeSelector, $eaSession2, $state, $window) {

  function destroySession() {

    try {
      // cache idp type
      var idp = $eaSession2.getSession().IdentityProvider;

      // kill the session
      $eaSession2.destroySession();

      return idp;
    } catch(e) {
      return null;
    }
  }

  // Signout
  $scope.signOut = function() {

    // destroy the session
    destroySession();

    // call the session signout
    $eaSession2.signOut();
  };

  $scope.refreshSession = function() {

    // destroy the session
    var idp = destroySession();

    // redirect to signin
    if (idp) {
      $state.go('public.signin', {idp: idp});
    } else {
      $state.go('public.signin');
    }
  };

  $scope.leaveActAs = function() {
    // leave the act-AsMode
    $eaSession2.leaveActAs();
  };

  $scope.visitProfile = function() {
    $eaSession2.visitProfileInSTS(true);
  }
});
