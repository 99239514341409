'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddStep02EaCtrl', function ($scope, $eaBackend, $busy, jwtHelper, $state, $eaCurrentSession) {

  $scope.formAddToken = {
    team: $state.params.team ? $state.params.team : 'personal',
    ea: null,
    eaType: null,
    token: null
  };

  $scope.next = function() {
    $busy.during($eaBackend.dataEARegisterContract($scope.formAddToken.team, $scope.formAddToken.ea,  $scope.formAddToken.token, $eaCurrentSession.DataToken).then(function () {
      return $state.go('authorized.reportAdd.step03', { team: $scope.formAddToken.team, enrollment: $scope.formAddToken.ea});
    }).catch(function (error) {

      if (error.status === 409 && error.data && error.data.Message) {
        $scope.registerError = error.data.Message;
      } else {
        $scope.registerError = error.data + ' (' + error.statusText + ')';
      }
    }));
  };


  $scope.tokenFieldChanged = function() {

    try {
      // read the token from the input field
      var decodedToken = jwtHelper.decodeToken($scope.formAddToken.token);

      // check if we have an enrollment number, if so add
      if (decodedToken.EnrollmentNumber !== undefined) {
        $scope.formAddToken.ea = decodedToken.EnrollmentNumber;
      } else {
        $scope.formAddToken.ea = null;
      }

      // check what type the contract is
      if (decodedToken.ReportView !== undefined) {
        $scope.formAddToken.eaType = decodedToken.ReportView;
      } else {
        $scope.formAddToken.eaType = null;
      }
    } catch(e) {
      $scope.formAddToken.ea = null;
      $scope.formAddToken.eaType = null;
    }
  };
});
