'use strict';

//
// ARRAY
//

// removes an object from the array
Array.prototype.removeObject = function(obj) {
  var idx = this.indexOf(obj);
  if (idx !== -1) {
    this.splice(idx, 1);
  }
};

// removes an object by a given property
Array.prototype.removeObjectByProperty = function(property, propertyValue) {

  var foundItem = null;
  this.forEach(function(item) {
    if (item[property] === propertyValue) {
      foundItem = item;
    }
  });

  this.removeObject(foundItem);
};

// replaces an object by a given property
Array.prototype.replaceObjectByProperty = function(property, propertyValue, updatedObject) {

  var foundItem = null;
  this.forEach(function(item) {
    if (item[property] === propertyValue) {
      foundItem = item;
    }
  });

  if (!foundItem) { return; }

  var idxOfItem = this.indexOf(foundItem);
  if (idxOfItem === -1 ) { return; }

  this[idxOfItem] = updatedObject;
};


Array.prototype.sortOn = function(key){
  this.sort(function(a, b){
    if(a[key] < b[key]){
      return -1;
    }else if(a[key] > b[key]){
      return 1;
    }
    return 0;
  });

  return this;
};

Array.prototype.sortSimple = function(){
  this.sort(function(a, b){
    if(a < b){
      return -1;
    }else if(a > b){
      return 1;
    }
    return 0;
  });

  return this;
};


// Generates an index by a single property. This property must be an unique key
Array.prototype.buildIndex = function(objectKey) {
  var selfArray = this;

  var newObject = {};

  selfArray.forEach(function(element) {

    // get the key value
    var keyValue = element[objectKey];

    // set the value
    newObject[keyValue] = element;
  });

  return newObject;
};

Array.prototype.isSame = function(array2) {
  var array1 = this;
  return (array1.length == array2.length) && array1.every(function(element, index) {
    return element === array2[index];
  });
};

Array.prototype.appendArray = function(srcArray) {
  this.push.apply(this, srcArray);
};


function executeAndReduceArray(workingArray, cb, q) {
  if (workingArray.length === 0) { return q.resolve(); }

  return q.when(cb(workingArray[0])).then(function() {

    // resuce the array
    workingArray.splice(0, 1);

    // and call us again
    return q.when(executeAndReduceArray(workingArray, cb, q));
  });
}

Array.prototype.forEachExecuteAsyncOperationSequential = function(q, cb) {

  // copy all items on a working array
  var workingArray = []; workingArray.appendArray(this);

  // execute and reduce
  return executeAndReduceArray(workingArray, cb, q);
};

Array.prototype.forEachExecuteAsyncOperationParallel = function(q, cb) {

  // remeber the promises
  var promissesArray = [];

  // add the action
  this.forEach(function(item) {
    promissesArray.push(q.when(cb(item)));
  });

  // wait until all is done
  return q.all(promissesArray);
};

//
// Date
//
Date.prototype.currentMonthDefinition = function() {
  return this.getFullYear() + '-' + ('0' + (this.getMonth() + 1)).slice(-2);
};

Date.prototype.currentDayDefinition = function() {
  return this.currentMonthDefinition() + '-' + ('0' + (this.getDate() + 1)).slice(-2);
};

Date.prototype.addDays = function(days)
{
  var dat = new Date(this.getTime());
  dat.setDate(this.getDate() + days);
  return dat;
};

Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
};

//
// String
//
String.prototype.capitalizeFirstLetter = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.downCaseFirstLetter = function() {
  return this.charAt(0).toLowerCase() + this.slice(1);
};

String.prototype.removeHeading = function(s) {
  if (this.indexOf(s) === 0) {
    return this.substring(s.length);
  } else {
    return this;
  }
};

String.prototype.removeTrailing = function(s) {
  if (this.endsWith(s)) {
    return this.substring(0, this.length - s.length);
  } else {
    return this;
  }
};

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
};
