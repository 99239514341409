'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddStep01Ctrl', function ($scope, $eaBackend, $busy, $eaBackendDataModeSelector, $eaSession2 , $eaTeamManagement, $eaUserProfile, $state, $eaCurrentSession, $toolbar, $eaBilling, $ccDemoModeService) {

  // contains the form content
  $scope.formStep01 = {
    team: 'personal',
    contractType: 'ea'
  };

  // possible target teams
  $scope.targetTeams = [ { Id: 'personal', Name: 'Personal'} ];
  $scope.activatedPlanEA    = false;
  $scope.activatedPlanPayGo = false;
  $scope.activatedCostMgnt = false;

  $busy.during($eaUserProfile.load($eaCurrentSession.DataToken).then(function(profile) {

    // pre-select the right tema
    if ($state.params.teamid && $eaTeamManagement.isAdminInOfTeam(profile, $state.params.teamid)) {
      $scope.formStep01.team = $state.params.teamid;
    }

    $scope.teamChanged = function() {

      // check the team details
      profile.Teams.forEach(function(team) {

        if (team.Id === $scope.formStep01.team) {

          if ($eaBilling.hasRequiredPlan(team.AccountPlan, 'professional')) {
            $scope.activatedPlanEA    = true;
            $scope.activatedPlanPayGo = true;
            $scope.activatedCostMgnt = true;
            $scope.formStep01.contractType = 'ea';
          } else if ($eaBilling.hasRequiredPlan(team.AccountPlan, 'startup')) {
            $scope.activatedPlanEA    = false;
            $scope.activatedPlanPayGo = true;
            $scope.activatedCostMgnt = false;
            $scope.formStep01.contractType = 'paygo';
          } else if ($eaBilling.hasRequiredPlan(team.AccountPlan, 'basic')) {
            $scope.activatedPlanEA    = true;
            $scope.activatedPlanPayGo = true;
            $scope.activatedCostMgnt  = true;
            $scope.formStep01.contractType = 'ea';
          }
        }
      });

    };


    $scope.next = function() {

      if ($scope.formStep01.contractType === 'ea') {
        return $state.go('authorized.reportAdd.step02-ea', {team: $scope.formStep01.team});
      } else if ($scope.formStep01.contractType === 'costmgnt') {
          return $state.go('authorized.reportAdd.step02-costmgnt', {team: $scope.formStep01.team});
      } else if ($scope.formStep01.contractType === 'demo') {
        return $ccDemoModeService.enableDemoMode($eaCurrentSession);
      } else {
        return $state.go('authorized.reportAdd.step02-rate', {team: $scope.formStep01.team});
      }
    };

    // Check the license topics
    // $scope.activeTeam && $eaBilling.hasRequiredPlan($scope.activeTeam.AccountPlan, 'enterprise'))


    // Add Back Entry if we have contracts
    var contracts = $eaUserProfile.getContracts(profile);
    if (contracts && contracts.length > 0) {
      $toolbar.addItem('addEA.Back', 'Back to Reports', 'fa-chevron-left', null, true, '/signin');
    }

    // define the available options
    $scope.teamChanged();

    // load the team profiles if needed
    if ($eaTeamManagement.isAdminInAtLeastOneTime(profile)) {

      return $eaTeamManagement.getTeamProfilesByRole(profile, 'admin', false, $eaCurrentSession.DataToken).then(function(teamProfiles) {
        // add the profiles
        $scope.targetTeams = $scope.targetTeams.concat(teamProfiles);

      });
    }
  }));
});
