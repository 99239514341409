'use strict';

angular.module('azureCostsFeApp').controller('EaSignInCtrl', function ($q, $scope, $controller, $state, $location, $eaSession2, $eaUserProfile, $eaLocalSettings, $eaBackendDataModeSelector, $eaCspService, $ccDemoModeService) {
    var self = this;

    // inject mixins
    $.extend(self, $controller('SessionHelperCtrlMixin', {$scope: $scope, $eaBackendDataModeSelector: $eaBackendDataModeSelector, $eaSession2: $eaSession2, $state: $state }));

    // set the standard message
    $scope.action = 'Logging in...';
    $scope.showRefreshSessionButton = false;


    // start the magin signin process
    $eaSession2.lazy().then(function (session) {

      return $eaCspService.loadProfiles(session).then(function(cspProfiles) {

        // check if we prevent this
        if (!cspProfiles || cspProfiles.length == 0 || $state.params.csp === "false" || $state.params.team || $state.params.enrollment || $state.params.initial || $state.params.demo) {

          // at this point we have a valid session and should load the availabe contracts
          $eaUserProfile.load(session.DataToken).then(function (profile) {

            // get the contracts
            var contracts = $eaUserProfile.getContracts(profile);

            // check if we have contracts or not
            if (contracts.length === 0 && $state.params.demo === "1") {

              // enable the demo mode in the data selector
              $ccDemoModeService.enableDemoMode(session, {csp: false});

            }
            if (contracts.length === 0) {
              var nonPersonalTeams = profile.Teams.filter(function (t) {
                return t.Role === 'admin' && t.Id !== 'personal'
              }).map(function (t) {
                return t.Id
              });
              $state.go('authorized.teams.contracts.add', {teamid: nonPersonalTeams.length === 0 ? 'personal' : nonPersonalTeams[0]});
            } else {

              // build parameters
              var stateParams = {
                teamid: $state.params.team,
                eanumber: $state.params.enrollment
              };

              if (!stateParams.teamid) {
                stateParams.teamid = contracts.getAt(0).Team;
              }

              if ($state.params.initial) {
                stateParams.initial = $state.params.initial;
              }

              $state.go('authorized.teams.dashboards', stateParams);
            }

          }).catch(function (error) {
            $scope.action = 'Something went wrong: ' + error;
            $scope.showRefreshSessionButton = true;
          });
        } else {
          // open the csp portal
          $state.go('authorized.cspinit');
        }
      });
    });
});
